var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4"},[_c('admin-header'),_c('v-row',_vm._l((_vm.cards),function(ref){
var title = ref.title;
var link = ref.link;
var cols = ref.cols;
var alert = ref.alert;
var sm = ref.sm;
var md = ref.md;
return _c('v-col',{key:title,attrs:{"md":md,"cols":sm}},[_c('v-btn',{staticClass:"ma-4 ml-0 mb-2",attrs:{"to":link,"outlined":""}},[_vm._v(_vm._s(title))]),(alert)?_c('v-alert',{attrs:{"type":"info","outlined":""}},[_vm._v(_vm._s(alert))]):_c('v-row',_vm._l((cols),function(ref){
var title = ref.title;
var link = ref.link;
var params = ref.params;
var list = ref.list;
var alert = ref.alert;
return _c('v-col',{key:title},[_c('v-card',[(link)?_c('v-btn',{staticClass:"ma-4 ml-2",attrs:{"text":""},on:{"click":function($event){return _vm.goToLink({ link: link, params: params })}}},[_vm._v(_vm._s(title))]):_c('v-subheader',[_vm._v(_vm._s(title))]),(list.length > 0)?_c('v-list',_vm._l((list),function(ref){
var title = ref.title;
var subtitle = ref.subtitle;
var count = ref.count;
var link = ref.link;
var params = ref.params;
return _c('v-list-item',{key:'stu-' + link + '-' + title,on:{"click":function($event){return _vm.goToLink({ link: link, params: params })}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))]),(subtitle)?_c('v-list-item-subtitle',[_vm._v(_vm._s(subtitle))]):_vm._e()],1),(count)?_c('v-list-item-action',[_c('v-avatar',{attrs:{"size":"32","color":"info white--text"}},[_vm._v(_vm._s(count))])],1):_vm._e()],1)}),1):(alert)?_c('v-alert',{attrs:{"type":"info","outlined":""}},[_vm._v(_vm._s(alert))]):_vm._e()],1)],1)}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }