<template>
  <div class="ma-4">
    <admin-header></admin-header>
    <v-row>
      <v-col v-for="{ title, link, cols, alert, sm, md } in cards" :key="title" :md="md" :cols="sm">
        <v-btn :to="link" outlined class="ma-4 ml-0 mb-2">{{ title }}</v-btn>
        <v-alert v-if="alert" type="info" outlined>{{ alert }}</v-alert>
        <v-row v-else>
          <v-col v-for="{ title, link, params, list, alert } in cols" :key="title">
            <v-card>
              <v-btn v-if="link" class="ma-4 ml-2" text @click="goToLink({ link, params })">{{ title }}</v-btn>
              <v-subheader v-else>{{ title }}</v-subheader>
              <v-list v-if="list.length > 0">
                <v-list-item v-for="{ title, subtitle, count, link, params } in list" :key="'stu-' + link + '-' + title" @click="goToLink({ link, params })">
                  <v-list-item-content>
                    <v-list-item-title>{{ title }}</v-list-item-title>
                    <v-list-item-subtitle v-if="subtitle">{{ subtitle }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="count">
                    <v-avatar size="32" color="info white--text">{{ count }}</v-avatar>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-alert v-else-if="alert" type="info" outlined>{{ alert }}</v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.dashboard-card {
  height: 150px;
}
.dashboard-card:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>
<script>
import { onMounted, ref, computed, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    AdminHeader: () => import('@/components/student/music/adminHeader')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isDark = computed(() => 'settings' in user.value && 'darkMode' in user.value.settings ? user.value.settings.darkMode : false)
    const term = computed({
      get: () => root.$store.state.student.music.admin.term,
      set: (term) => root.$store.commit('student/setMusicAdmin', { term })
    })
    const terms = computed({
      get: () => root.$store.state.student.music.admin.terms,
      set: (terms) => root.$store.commit('student/setMusicAdmin', { terms })
    })
    const termLabel = computed(() => terms.value.reduce((prev, { text, value }) => value === term.value ? text : prev, ''))

    const cards = ref([])

    const classLevels = ref([
      { text: 'Freshmen', value: 'FR' },
      { text: 'Sophomores', value: 'SO' },
      { text: 'Juniors', value: 'JR' },
      { text: 'Seniors', value: 'SR' }
    ])

    onMounted(() => {
      root.$store.dispatch('student/loadMusicAdminTerms')
    })
    watch(term, async () => {
      if (term.value !== '') {
        const arr = []
        arr.push(await loadStudentCounts())
        arr.push(await loadEvents())
        arr.push(await loadJuries())
        cards.value = arr
      }
    })

    async function loadStudentCounts () {
      const aggregate = [
        { $match: {
          term: term.value,
          $or: [
            { 'academics.major': 'MUS' },
            { 'academics.minor': 'MUS' },
            { 'schedule.title': { $regex: 'MUS[1-4]0[1-8]' } }
          ]
        } },
        { $addFields: {
          MUS111: { $cond: { if: { $size: { $filter: { input: '$schedule', cond: { $eq: [{ $substr: ['$$this.title', 0, 6] }, 'MUS111'] } } } }, then: 'Y', else: 'N' } },
          MUS171: { $cond: { if: { $size: { $filter: { input: '$schedule', cond: { $eq: [{ $substr: ['$$this.title', 0, 6] }, 'MUS171'] } } } }, then: 'Y', else: 'N' } }
        } },
        { $project: {
          classLevel: '$academics.classLevel',
          major: { $cond: { if: { $in: ['MUS', '$academics.major'] }, then: 'Y', else: 'N' } },
          minor: { $cond: { if: { $in: ['MUS', '$academics.minor'] }, then: 'Y', else: 'N' } },
          MUS111: 1,
          MUS171: 1
        } },
        { $addFields: {
          group: { $switch: {
            branches: [
              { case: { $eq: ['$major', 'Y'] }, then: 'major' },
              { case: { $eq: ['$minor', 'Y'] }, then: 'minor' }
            ],
            default: 'other'
          } }
        } },
        { $group: { _id: { classLevel: '$classLevel', group: '$group' }, count: { $sum: 1 } } },
        { $group: { _id: '$_id.group', counts: { $push: { k: '$_id.classLevel', v: '$count' } } } },
        { $project: { group: '$_id', counts: { $arrayToObject: '$counts' } } },
        { $sort: { group: 1 } }
      ]
      const { data } = await root.$feathers.service('student/term-detail').find({ query: { aggregate } })
      return {
        title: 'Students',
        sm: 12,
        md: 12,
        link: 'admin/students',
        cols: data.map(({ _id: title, counts }) => {
          const obj = { title: title + 's', link: 'admin/students', params: { stuGroup: title, classLevel: [] }, list: [] }
          for (const { text, value } of classLevels.value) {
            obj.list.push({ title: text, count: counts[value] || '0', link: 'admin/students', params: { stuGroup: title, classLevel: [value] } })
          }
          return obj
        })
      }
    }

    async function loadEvents () {
      const recent = await loadRecentEvents()
      const upcoming = await loadUpcomingEvents()
      return {
        title: 'Event Scanning',
        link: 'admin/events',
        sm: 12,
        md: 6,
        cols: [
          {
            title: 'Recent Events',
            list: recent,
            alert: recent.length === 0 ? 'There are no recent events for the selected term. Click the Event Scanning button above to add events.' : false
          },
          {
            title: 'Upcoming Events',
            list: upcoming,
            alert: upcoming.length === 0 ? 'There are no upcoming events for the selected term. Click the Event Scanning button above to add events.' : false
          }
        ]
      }
    }
    async function loadUpcomingEvents () {
      const aggregate = [
        { $match: { term: term.value, $expr: { $gte: ['$date', { $dateFromString: { dateString: new Date() } }] } } },
        { $project: { title: '$name', date: 1, attended: { $size: '$students' } } },
        { $sort: { date: -1 } },
        { $limit: 4 }
      ]
      const { data } = await root.$feathers.service('student/music/scan-event').find({ query: { aggregate } })
      return data.map((row) => { return { ...row, subtitle: stringFormatDate(row.date), link: 'admin/events' } })
    }
    async function loadRecentEvents () {
      const aggregate = [
        { $match: { term: term.value, $expr: { $lte: ['$date', { $dateFromString: { dateString: new Date() } }] } } },
        { $project: { title: '$name', date: 1, attended: { $size: '$students' } } },
        { $sort: { date: -1 } },
        { $limit: 4 }
      ]
      const { data } = await root.$feathers.service('student/music/scan-event').find({ query: { aggregate } })
      return data.map((row) => { return { ...row, subtitle: stringFormatDate(row.date), link: 'admin/students', params: { event: row._id, eventAttended: true, group: null, classLevel: [] } } })
    }
    async function loadJuries () {
      const instruments = await loadJuryInstruments()
      const instructors = await loadJuryInstructors()
      return {
        title: 'Juries',
        link: 'admin/jury',
        sm: 12,
        md: 6,
        cols: [
          {
            title: 'Student-Selected Instruments',
            list: instruments,
            alert: instruments === false ? 'Juries have not been set up for the selected term. Please click the Juries button above to do the setup for the selected term.' : false
          },
          {
            title: 'Student-Selected Instructors',
            list: instructors,
            alert: instructors === false ? 'Juries have not been set up for the selected term. Please click the Juries button above to do the setup for the selected term.' : false
          }
        ]
      }
    }
    async function loadJuryInstruments () {
      const aggregate = [
        { $match: { term: term.value, instrument: { $exists: true, $ne: '' } } },
        { $group: { _id: '$instrument', count: { $sum: 1 } } },
        { $project: { title: '$_id', count: 1 } },
        { $sort: { title: 1 } }
      ]
      const { data } = await root.$feathers.service('student/music/jury-slot').find({ query: { aggregate } })
      const temp = data.map((row) => { return { ...row, link: 'admin/jury', params: { instrument: [row.title], instructor: [] } } })
      if (data.length === 0) {
        const { total } = await root.$feathers.service('student/music/jury-slot').find({ query: { term: term.value, $limit: 0 } })
        if (total === 0) {
          return false
        }
      }
      return temp
    }
    async function loadJuryInstructors () {
      const aggregate = [
        { $match: { term: term.value, instructor: { $exists: true, $ne: '' } } },
        { $group: { _id: '$instructor', count: { $sum: 1 } } },
        { $project: { title: '$_id', count: 1 } },
        { $sort: { title: 1 } }
      ]
      const { data } = await root.$feathers.service('student/music/jury-slot').find({ query: { aggregate } })
      const temp = data.map((row) => { return { ...row, link: 'admin/jury', params: { instructor: [row.title], instrument: [] } } })
      if (data.length === 0) {
        const { total } = await root.$feathers.service('student/music/jury-slot').find({ query: { term: term.value, $limit: 0 } })
        if (total === 0) {
          return false
        }
      }
      return temp
    }

    function goToLink ({ link, params }) {
      if (params) {
        root.$store.commit('student/setMusicAdmin', params)
      }
      root.$router.push(link)
    }

    return {
      user,
      isDark,
      term,
      terms,
      termLabel,
      cards,
      classLevels,
      goToLink,
      stringFormatDate
    }
  }
}
</script>
